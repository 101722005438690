// src/pages/About.jsx
import React from 'react';
import Whoweare from './Whoweare';

const About = () => {
  return (
    <div className="select-none">
      <Whoweare />
    </div>
  );
};

export default About;
